import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ClearFilters from 'components/ClearFilters';
import * as React from "react";
import { isArray } from "lodash";

const useStyles = makeStyles({
  contentHeaderRoot: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid grey",
    backgroundColor: "whitesmoke",
    padding: 8,
  },
  contentTitle: {
    flexShrink: 0,
    minWidth: 200,
    alignSelf: "flex-start",
  },
  contentActions: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
  },
  filterTag: {
    fontSize: '14px', border: '2px solid #c8c5c5', borderRadius: '5px', padding: '0px 5px',
    fontWeight: 500,
  }
});

function ContentHeader({
  title, children, clearFilter, BaseFilters, filters, setFilters
}) {
  const classes = useStyles();

  const [appliedFilters, setAppliedFilters] = React.useState({})

  React.useEffect(() => {
    let uniqueFilters = {};
    for (let key in filters) {
        if (!(key in BaseFilters) && filters[key]?.length) {
            if (key === "client") {
                uniqueFilters["Client"] = filters["client_label"] || "-"; 
            } else if (key !== "client_label") {
                const displayValue = isArray(filters[key])
                    ? filters[key].join(", ")
                    : filters[key];
                uniqueFilters[key] = displayValue;
            }
        }
    }
    setAppliedFilters(uniqueFilters);
}, [BaseFilters, filters]);


  return (
    <>
      <Box className={classes.contentHeaderRoot}>
        <Box className={classes.contentTitle}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Box className={classes.contentActions}>
          <Box display="flex" flexWrap="wrap" gap="5px">
              {Object.keys(appliedFilters)?.map((filterKey, i) => (
                  <Typography key={i} className={classes.filterTag}>
                      {filterKey}: {appliedFilters?.[filterKey]}
                  </Typography>
              ))}
          </Box>
          {clearFilter && (
              <ClearFilters
                  BaseFilters={BaseFilters}
                  setFilters={setFilters}
                  filters={filters}
              />
          )}
          {children}
      </Box>

      </Box>
    </>
  );
}

export default ContentHeader;