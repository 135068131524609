import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { makeStyles } from '@mui/styles';
import { Checkbox, FormHelperText, Typography } from '@mui/material';
import { getTemplates } from 'services/templates';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    error: {
        marginLeft: 16,
    },
    root: {
        margin: "8px 0",
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",
        "& .MuiInputLabel-root": {
            fontSize: "12px !important",
            fontWeight: "normal !important",
        },
        "& .MuiFilledInput-root": {
            fontSize: 12,
            fontWeight: "normal",
            borderRadius: "6px",
            backgroundColor: "white",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
            '&>*': {
                padding: 10
            }
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: "#E4E3E8",
            borderRadius: "6px",
            fontSize: 12,
            fontWeight: "normal",
            '& fieldset': {
                borderRadius: "6px",
                borderColor: "transparent",
                fontWeight: "normal",
                fontStyle: "normal",
                fontFamily: "'Inter', sans-serif",
                fontSize: 12,
            },
            '&:hover fieldset': {
                borderColor: '#02569D',
                borderRadius: "6px",
                fontSize: 12,
                fontWeight: "normal",

            },
            '&.Mui-focused fieldset': {
                borderColor: '#02569D',
                borderRadius: "6px",
                fontSize: 12,
                fontWeight: "normal",
            },
        },
    },
}));

function InterviewTemplateMultiSelect({
    filters,
    onChange = (interviewTemplates) => { },
    setAllTemplateList = (list) => { }
}) {
    const classes = useStyles();
    const [interviewTemplates, setInterviewTemplates] = React.useState([]);

    const handleChange = (e, newValue) => onChange(newValue);

    React.useEffect(() => {
        (async () => {
            const { interviewTemplates } = await getTemplates(filters);
            const initialTemplates = interviewTemplates.map((el) => (el._id));
            setAllTemplateList(initialTemplates);
            setInterviewTemplates(interviewTemplates);
        })();
    }, [filters]);
    
    return (
        <>
            <Autocomplete
                fullWidth
                multiple
                id="templates"
                size="small"
                options={interviewTemplates}
                disableCloseOnSelect
                onChange={handleChange}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                    <Typography
                        {...props}
                        component="li"
                        sx={{ fontSize: 14, fontWeight: "normal", color: "inherit" }}
                    >
                        <Checkbox
                            icon={icon}
                            value={option.value}
                            checkedIcon={checkedIcon}
                            checked={selected}
                        />
                        {option.name}
                    </Typography>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Choose Templates"
                        className={classes.root}
                    />
                )}
            />
        </>
    );
}

export default InterviewTemplateMultiSelect;