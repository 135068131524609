import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import ContentShell from "components/ContentShell";
import IFrame from "components/IFrame";
import MagicLinks from "components/forms/MagicLinks";
import SEPWebhookIFrame from "components/SEPWebhookIFrame";
import MockMagicLink from "components/forms/MockMagicLink";
import Performance from "components/Performance";
import OnboardUser from "components/forms/OnboardUser";
import AssessmentLink from "components/forms/AssessmentLink";

export default function Integrations() {
  return (
    <Routes>
      <Route path="/" element={<IntegrationsLayout />}>
        <Route index element={<Navigate to="magic-links" />} />
        <Route path='magic-links' element={<MagicLinks />} />
        <Route path='iframe' element={<IFrame />} />
        <Route path='mock' element={<MockMagicLink />} />
        <Route path='performance' element={<Performance />} />
        <Route path='onboard' element={<OnboardUser />} />
        <Route path='assessment-links' element={<AssessmentLink />} />
      </Route>
    </Routes>
  );
}

const SideBarLinks = [
  {
    label: "Magic Links",
    path: 'magic-links',
    allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
  },
  {
    label: "IFrame",
    path: 'iframe',
    allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
  },
  {
    label: "Mock magic link",
    path: 'mock',
    allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
  },
  {
    label: "Performance",
    path: 'performance',
    allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
  },
  {
    label: "Onboard user",
    path: 'onboard',
    allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
  },
  {
    label: "Assessment Links",
    path: 'assessment-links',
    allowedRoles: ["ADMIN", "PRODUCT", "SALES",],
  },
];

function IntegrationsLayout() {
  return (
    <ContentShell sideBarLinks={SideBarLinks}>
      <Outlet />
    </ContentShell>
  );
}