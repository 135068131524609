import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import CustomButton from 'components/CustomButton';
import CustomChipArray from 'components/CustomChipArray';
import { SEPTemplateSelect } from 'components/TemplateSelect';
import { useSnackbar, useLoading } from "contexts";
import { copyToClipboard } from "utils";
import { exportSEPTemplateAnalytics, getSEPTemplates } from "services";
import ClientSelect from 'components/ClientSelect';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function ExportSEPAnalyticsDialog({
    open,
    onClose = () => { },
    onSuccess = () => { },
}) {
    const snackbar = useSnackbar();
    const loading = useLoading();
    const [emails, setEmails] = React.useState([]);
    const [client, setClient] = React.useState(null);
    const [template, setTemplate] = React.useState(null);
    const [responseUrl, setResponseUrl] = React.useState(false);
    const [createdAtStart, setCreatedAtStart] = React.useState(null);
    const [createdAtEnd, setCreatedAtEnd] = React.useState(null);

    const handleEmailsChange = (newEmails) => {
        setEmails(newEmails);
    }
    const filters = React.useMemo(() => {
        return { user: client?._id };
    }, [client]);

    React.useEffect(() => {
        setEmails([]);
        setResponseUrl(false);
    }, [open]);

    const handleExportAnalytics = async () => {
        try {
            loading.show();
            if (!emails.length) {
                throw new Error('Please provide at least one email address.');
            }

            let templateIds = [];
            if (template?.length) {
                templateIds = template.map((t) => t._id);
            } else {
                const { sepTemplates } = await getSEPTemplates(filters);
                templateIds = sepTemplates.map((t) => t._id);

                if (!templateIds.length) {
                    throw new Error('No templates available to export.');
                }
            }
            const startDate = createdAtStart || '0000-01-01T00:00:00.000Z';
            const endDate = createdAtEnd || '9999-12-31T23:59:59.999Z';

            const { url } = await exportSEPTemplateAnalytics(
                templateIds,
                emails,
                startDate,
                endDate
            );

            setResponseUrl(url);
            snackbar.showSnackbar('Export successful!', 'success', true);
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error', true);
        } finally {
            loading.hide();
        }
    };
    const handleCopyUrlClick = () => {
        copyToClipboard(responseUrl);
        snackbar.showSnackbar("URL copied to clipboard", "success", true);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle >
                Export SEP Template analytics
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ minWidth: 500, maxWidth: 900, overflow: "hidden" }}>
                {!responseUrl ? (
                    <>
                        <ClientSelect onChange={setClient} sepClients />
                        <br />
                        <SEPTemplateSelect onChange={setTemplate} filters={filters} />
                        <br />
                        <Box display={"flex"} flexDirection={"row"} flexWrap={"nowrap"} justifyContent={"space-around"} >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="CreatedAt Begin"
                                    onChange={(value) => {
                                        const dateString = (new Date(value.$d)).toISOString();
                                        setCreatedAtStart(dateString)
                                    }}
                                    sx={{ maxWidth: "48%" }}
                                />
                                <DatePicker
                                    label="CreatedAt End"
                                    sx={{ maxWidth: "48%" }}
                                    onChange={(value) => {
                                        const dateString = (new Date(value.$d)).toISOString();
                                        setCreatedAtEnd(dateString)
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                        <br />
                        <br />
                        <CustomChipArray
                            InputProps={{
                                autoFocus: true,
                                type: 'email',
                                name: "email",
                                placeholder: "Add emails for sheet permissions...",
                            }}
                            onValuesChange={handleEmailsChange}
                        />
                        <br />
                        <br />
                        <CustomButton
                            variant="contained"
                            disabled={emails.length <= 0 }
                            onClick={handleExportAnalytics}
                        >
                            Export
                        </CustomButton>
                    </>
                ) : (
                    <Box
                        border="1px solid lightgrey"
                        my="1em"
                        p="1em"
                        display="flex"
                        alignItems="center"
                    >
                        <Box flexGrow={1} overflow="hidden">
                            <Typography
                                variant="subtitle1"
                                color="blue"
                                component="a"
                                sx={{ wordWrap: "break-word" }}
                                href={responseUrl}
                                target={"_blank"}
                            >
                                {responseUrl}
                            </Typography>
                        </Box>
                        <Box ml={1}>
                            <IconButton variant="outlined">
                                <ContentCopyIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={handleCopyUrlClick}
                                />
                            </IconButton>
                        </Box>
                    </Box>
                )}
                <CustomButton
                    variant="outlined"
                    onClick={onClose}
                >
                    Cancel
                </CustomButton>
            </DialogContent>
        </Dialog>
    );
}

export default ExportSEPAnalyticsDialog;