import * as Yup from "yup";

export const QuestionTypes = [
    {
        _id: "spoken",
        name: "Spoken"
    },
    {
        _id: "written",
        name: "Written"
    },
    {
        _id: "mcq",
        name: "MCQ"
    },
    {
        _id: "scq",
        name: "SCQ"
    },
];

export const FieldConfigs = {
    question: {
        schema: Yup.string().required("Question is required"),
        placeholder: "Type your Question Here...e.g. Tell me about yourself?",
        label: "Q*",
    },
    type: {
        schema: Yup
            .string()
            .oneOf(QuestionTypes.map(type => type._id))
            .required("Question type is required"),
        placeholder: "",
        label: "Type of question*",
    },
    options: {
        schema: Yup
            .array(Yup.string()).min(2).max(5)
            .required("Options are required"),
        placeholder: "Add options...",
        label: "Options"
    },
    answers: {
        schema: Yup
            .array(Yup.string()).min(1).max(5)
            .required("Answers are required"),
        placeholder: "Add correct answers...",
        label: "Answers"
    },
    hint: {
        schema: Yup.string(),
        placeholder: "Add a hint... (remember the day we met)",
        label: "Hint:",
    },
    allottedTime: {
        schema: Yup
            .number()
            .min(1, "Should be greater than 0")
            .max(20, "Should be less than 20 mins")
            .required("Must be greater than 1 and less than 20 mins"),
        placeholder: "",
        label: "Time for question (mins)*",
    },
};

export const TemplateValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name of the Interview is required"),
    user: Yup.string().required("Select a Client"),
});

export const QuestionValidationSchema = Yup.object().shape({
    question: Yup.string().required('Question is required'),
    allottedTime: Yup
        .number()
        .min(0, "Should be greater than 0")
        .max(20, "Should be less than 20 mins")
        .required("Must be greater than 1 and less than 10mins"),
    type: Yup
        .string()
        .oneOf(QuestionTypes.map(type => type._id))
        .required("Question type is required"),
    hint: Yup.string().trim(),
    options: Yup.array().of(Yup.string().trim())
        .when('type', { is: "mcq", then: Yup.array().min(2).max(4).required('Options are required') }),
    answers: Yup.array()
        .when('type', {
            is: (type) => ['mcq', 'scq'].includes(type),
            then: Yup.array().required(),
        }).test(
            'one-of-options',
            '${path} must be from the options',
            (value, context) => value.every((x) => context.parent.options.includes(x)),
        ),
}).required();