import React from "react";
import { useNavigate } from "react-router-dom";
import { useLoading, useSnackbar } from "contexts";

export function useList({
  itemName,
  getItems,
  deleteItemById,
  initialFilters,
  initialSortModel,
  initialPageSize,
  initialPageNo,
}) {
  const path = window.location.pathname;

  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const loader = useLoading();

  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [items, setItems] = React.useState([]);
  const [_filters, _setFilters] = React.useState(initialFilters || {});
  const [_sortModel, _setSortModel] = React.useState(
    JSON.parse(localStorage.getItem(`sorting-${path}`)) ||
    initialSortModel ||
    {}
  );
  const [pageNo, setPageNo] = React.useState(initialPageNo || 0);
  const [pageSize, setPageSize] = React.useState(initialPageSize || 10);

  const loadListItems = React.useCallback(async () => {
    setLoading(true);
    try {
      const result = await getItems({
        ..._filters,
        ..._sortModel,
        pageNumber: pageNo + 1,
        pageSize: pageSize,
      });

      const newItems = result[itemName] || [];

      setItems(newItems);
      setTotal(result.total || newItems.length);
    } catch (error) {
      console.error("DEBUG::useList:getItems->", error);
      snackbar.showSnackbar(error.message, "error");
    } finally {
      setLoading(false);
    }
  }, [pageNo, pageSize, _filters, _sortModel, itemName]);

  const deleteItem = async (itemId) => {
    setLoading(true);
    try {
      await deleteItemById(itemId);

      await loadListItems();
      snackbar.showSnackbar("Fetched list items successfully!!", "success");
    } catch (error) {
      console.error("DEBUG::useList_.deleteItem", error);
      snackbar.showSnackbar(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const editItem = (e, itemId = 'create') => navigate(itemId);

  React.useEffect(() => {
    if (loading) loader.show();
    else loader.hide();
  }, [loading]);

  React.useEffect(() => {
    loadListItems();
  }, [_filters, _sortModel, pageNo, pageSize]);

  const setSortModel = React.useCallback((sortModel) => {
    _setSortModel(sortModel);

    localStorage.setItem(
      `sorting-${path}`,
      JSON.stringify(sortModel)
    );
  }, []);

  const setFilters = React.useCallback((filters) => {
    _setFilters(filters);
  }, []);


  return {
    loading,
    total,
    pageNo,
    pageSize,
    items,
    filters: _filters,
    setFilters,
    setSortModel,
    setPageNo,
    setPageSize,
    deleteItem,
    editItem,
    refresh: loadListItems
  };
}