import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import shallow from "zustand/shallow"
import Box from '@mui/material/Box';
import { useLoading, useSnackbar } from "contexts";
import { getSEPTemplateById } from 'services/sepTemplates';
import TemplateDetails from './TemplateDetails';
import QuestionSection from "./QuestionSection";
import GSheetImportDialog from 'components/dialogs/GSheetImportDialog';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import Session from 'utils/Session';
import { QuestionTypes } from './constants';
import useEditorStore from './editorStore';


function SEPTemplateEditor() {
    const snackbar = useSnackbar();
    const loading = useLoading();
    let { id } = useParams();

    const [setQuestions, setTemplate, setCreator, reset] = useEditorStore((state) => [
        state.setQuestions, state.setTemplate, state.setCreator, state.reset
    ], shallow);

    const [openTemplateImport, setOpenTemplateImport] = React.useState(false);

    useEffect(() => {
        (async () => {
            if (id && id !== "create") {
                try {
                    loading.show();
                    let { questions, ...sepTemplate } = await getSEPTemplateById(id);
                    questions = questions.map(question => {
                        question.allottedTime = question.allottedTime / 60;
                        return question;
                    });

                    setQuestions(questions);
                    setTemplate(sepTemplate);
                    setCreator(true);
                } catch (error) {
                    console.log(error);
                    snackbar.showSnackbar("Unable to load the template", "error");
                } finally {
                    loading.hide();
                }
            } else {
                reset();
            }
        })();
    }, [id]);


    const onClose = () => {
        setOpenTemplateImport(false);
    }

    const onSuccess = (importedQuestions) => {
        let tempQuestions = importedQuestions.map(questionObject => {
            const type = QuestionTypes.find(
                x => x._id === questionObject.questionType?.toLowerCase()
            )?._id;

            let tempQuestion = { allottedTime: "", added: true, resources: "" };
            tempQuestion.question = questionObject.question;
            tempQuestion.hint = questionObject.hint;
            tempQuestion.allottedTime = Math.round((questionObject.allottedTime || 60) / 60);
            tempQuestion.type = type || "spoken";
            tempQuestion.user = Session.userId;
            tempQuestion.options = questionObject?.options?.split(",") || [];
            tempQuestion.answers = questionObject?.answers?.split(",") || [];
            return tempQuestion;
        });
        setQuestions(tempQuestions);
        onClose();
    }

    return (<>
        <div>
            <ContentHeader
                title={(!id || id === "create") ? "Create SEP Template" : "SEP Template"}
            >
                {id === "create" && (<CustomButton
                    variant="outlined"
                    onClick={() => setOpenTemplateImport(true)}
                >
                    Import from Google Sheet
                </CustomButton>
                )}
            </ContentHeader>

            <Box position="relative" display="flex">
                <TemplateDetails />
                <QuestionSection />
            </Box>
        </div>
        {id === "create" &&
            <GSheetImportDialog
                open={openTemplateImport}
                title="Import Template from Google Sheet"
                onSuccess={onSuccess}
                onClose={onClose}
                isSep={true}
            />
        }
    </>)
}

export default SEPTemplateEditor;